<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              預約管理
            </b-breadcrumb-item>

            <b-breadcrumb-item active>
              預約列表
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="bookingPresets"
                :fields="fields"
              >
                <template #cell(name)="data">
                  <RouterLink :to="{ name: 'BookingList', params: { id: data.item.id }}">
                    {{ data.item.name }}
                  </RouterLink>
                </template>
                <template #cell(is_enabled)="data">
                  <b-badge v-if="data.item.config.is_enabled == true" variant="success">啟用</b-badge>
                  <b-badge v-if="data.item.config.is_enabled == false" variant="secondary">停用</b-badge>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_CREATE])"
                    variant="inverse-success"
                    :to="{ name: 'BookingCreate', params: { id: data.item.id }}"
                  >
                    <span class="mdi mdi-calendar-plus"></span> 新增預約
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_LIST])"
                    variant="inverse-warning"
                    :to="{ name: 'BookingList', params: { id: data.item.id }}"
                  >
                    <span class="mdi mdi-format-list-bulleted"></span> 查看預約資料
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_PRESET_DELETE])"
                    variant="inverse-danger"
                    @click="deleteBookingPreset(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchBookingPresets"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>

</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import bookingApi from "@/apis/booking";
import { mapState } from "vuex";

export default {
  data() {
    return {
      consts: consts,
      keyword: '',
      showLoading: false,
      fieldsInit: false,
      fields: [
        {
          key: "name",
          label: "名稱",
          sortable: true,
        },
        {
          key: "bookings_count",
          label: "預約筆數",
          sortable: true,
        },
        {
          key: "is_enabled",
          label: "是否開放預約",
        },
        {
          key: 'actions',
          label: '管理'
        },
      ],
      bookingPresets: [],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.fetchBookingPresets();
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async fetchBookingPresets () {
      try {
        this.showLoading = true;
        const response = await bookingApi.getBookingPresets({
          per_page: this.perPage,
          page: this.currentPage,
        });
        this.bookingPresets = response.data.data;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deleteBookingPreset(bookingPreset) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "刪除預約設定檔",
          text: `是否要將此預約設定檔刪除？`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "移除",
          reverseButtons: true,
        })

        if (! result.value) {
          return
        }

        await bookingApi.deleteBookingPreset(bookingPreset.id);
        this.$swal('刪除成功', '', 'success');
        await this.fetchBookingPresets();
      } catch (error) {
        console.error(error);
        this.$swal('刪除失敗', '', 'error');
      } finally {
        this.showLoading = false;
      }
    },
  }
}
</script>
